const recaptchaHelper = () => {
    function expiredCallback(response, id) {
        document.getElementById(`${id}`).value = '';
    }

    function removeValidation(id) {
        const hiddenInput = document.getElementById(id);
        const fieldElement = hiddenInput.parentElement;
        const validationMessage = fieldElement.querySelector('.field-validation-error');

        fieldElement.classList.remove('has-error');

        if (validationMessage) {
            validationMessage.remove();
        }
    }

    function verifyCallback(response, id) {
        document.getElementById(`${id}`).value = response;
        removeValidation(id);
    }

    function registerRecaptcha() {
        const recaptchaFields = document.querySelectorAll('.g-recaptcha');

        if (!recaptchaFields) {
            return;
        }

        window.formRecaptcha = {};
        recaptchaFields.forEach((recaptcha) => {
            const id = recaptcha.getAttribute('id');

            window.formRecaptcha[id] = window.grecaptcha.render(id, {
                sitekey: recaptcha.dataset.sitekey,
                theme: recaptcha.dataset.theme,
                'callback': function callback(response) {
                    return new Promise(((resolve, reject) => {
                        verifyCallback(response, recaptcha.dataset.recaptchaValField);

                        resolve();
                    }));
                },
                'expired-callback': function expiredCb(response) {
                    return new Promise(((resolve, reject) => {
                        expiredCallback(response, recaptcha.dataset.recaptchaValField);

                        resolve();
                    }));
                },
            });
        });
    }

    window.registerRecaptcha = registerRecaptcha;
};

export default recaptchaHelper;
