import * as log from 'loglevel';

//configure global logging
//log.setLevel(PRODUCTION ? log.levels.WARN : log.levels.TRACE);

export const breakPoint = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1400
}
