// import { ValidationService } from 'aspnet-client-validation';

function init() {
    if (!window.aspnetValidation) {
        console.warn('No aspnet-client-validation found. Skipping validation initialization.');
        return;
    }

    let validationService = new window.aspnetValidation.ValidationService();

    const required = function (value, element, params) {
        if (element.dataset && element.dataset.validate === "false") {
            return true;

        }
        if (!value) {
            if (element.offsetHeight === 0) {
                return true;
            }
        }
        // this is from Umbraco Forms
        if (element.type.toLowerCase() === "checkbox" || element.type.toLowerCase() === "radio") {
            var allCheckboxesOfThisName = element.form.querySelectorAll("input[name='" + element.name + "']");
            for (var i = 0; i < allCheckboxesOfThisName.length; i++) {
                if (allCheckboxesOfThisName[i].checked === true) {
                    return true;
                }
            }
            return false;
        }

        return Boolean(value);
    }

    const requiredif = function (value, element, params) {
        const { dependentproperty, targetvalue } = params;
        const targetElement = document.getElementsByName(dependentproperty)[0];

        if (!targetElement) {
            return true;
        }

        if (targetElement.type && targetElement.type === 'checkbox') {
            const elementValue = targetElement.checked;

            return elementValue.toString().toLowerCase() !== targetvalue.toLowerCase();
        }

        return targetElement.value !== targetvalue;
    };

    const mustbetrue = function (value, element, params) {
        if (element.type && element.type === 'checkbox') {
            return element.checked;
        }

        return !value;
    };

    const extension = function (value, element, params) {
        if (!element.files || !element.files.length) {
            return true;
        }

        const { extensions } = params;

        if (!extensions) {
            console.warn('Extension are blank!')
            return true;
        }

        let isValid = true;

        Array.from(element.files).forEach(function (file) {
            if (!isValid) {
                return;
            }

            if (file.name.indexOf('.') === -1) {
                isValid = false;
            }

            const extension = file.name.split('.').pop();

            if (!extensions.includes(extension.toLowerCase())) {
                isValid = false;
            }
        });

        return isValid;
    };

    const filesize = function (value, element, params) {
        if (!element.files || !element.files.length) {
            return true;
        }

        let totalFileSize = true;
        const { min, max } = params;

        Array.from(element.files).forEach(function (file) {
            console.log(file);

            totalFileSize = totalFileSize + file.size;
        });

        return totalFileSize >= parseInt(min, 10) && totalFileSize <= parseInt(max, 10);
    };

    const mindate = function (value, element, params) {
        console.warn('Not implemented!');
        const date = new Date(value);

        if (date instanceof Date && !isNaN(date.valueOf())) {
            const { min } = params;
            const minDate = new Date(min);

            return date >= minDate;
        }

        return false;
    }

    const maxdate = function (value, element, params) {
        console.warn('Not implemented!');
        const date = new Date(value);

        if (date instanceof Date && !isNaN(date.valueOf())) {
            const { max } = params;
            const maxDate = new Date(max);

            return date <= maxDate;
        }

        return false
    };

    validationService.addProvider("requiredcb", required);
    validationService.addProvider("required", required);
    validationService.addProvider("mustbetrue", mustbetrue);
    validationService.addProvider("extension", extension);
    validationService.addProvider("filesize", filesize);
    validationService.addProvider("requiredif", requiredif);
    validationService.addProvider("mindate", mindate);
    validationService.addProvider("maxdate", maxdate);
    validationService.bootstrap();

    window.validationService = validationService;
}

export default init;
